import { render, staticRenderFns } from "./TheFooterV2.vue?vue&type=template&id=135bb1a9&scoped=true&"
import script from "./TheFooterV2.vue?vue&type=script&lang=js&"
export * from "./TheFooterV2.vue?vue&type=script&lang=js&"
import style0 from "./TheFooterV2.vue?vue&type=style&index=0&id=135bb1a9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "135bb1a9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterContact: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/V2/FooterContact.vue').default,FooterSocials: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/V2/FooterSocials.vue').default,FooterLocale: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/V2/FooterLocale.vue').default,RatingPresentation: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/rating/RatingPresentation.vue').default,FooterLine: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/V2/FooterLine.vue').default,FooterSection: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/V2/FooterSection.vue').default,FooterScrollToTop: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/FooterScrollToTop.vue').default})
